<template>
  <div class="error-page-wrapper">
    <div>
      <h1> Something went wrong... </h1>
      <div>
        If the problem persists, please contact your Lendela rep for help.
        <br>
        <button class="grey-button sharp-corner-button" @click="reloadPage()"> Reload page </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '../store/app';

export default {
  props: ['error'],

  data () {
    return {
      appStore: useAppStore(),
    }
  },

  methods: {
    reloadPage () {
      this.appStore.clearAppStore();
      location.reload();
    }
  }
}
</script>

<style>
.error-page-wrapper {
  background-color: #F4F4FB;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  padding: 20px;
}

.error-page-wrapper h1 {
  color: #39393F;
  margin-bottom: 8px;
}
</style>
